import * as decConfig from '../decconfig';
import axios from 'axios';
import {
  orderencrypt,
  decryptdataresponse,
  headerkeys,
  decrypt,
} from './Encryption';
import auth from '../../src/web/auth/auth';
import authjt from '../../src/web/auth/authjwt';
require('dotenv').config();

const APP_Key_ID = decConfig.Dec_APP_Key_ID;
const APP_Type = decConfig.Dec_APP_Type;
const APP_Key_Type = decConfig.Dec_APP_Key_Type;
const APP_secret_Key = decConfig.Dec_APP_secret_Key;

let apihost = 'https://grocery.randomsoftsolution.in';

axios.defaults.baseURL = "https://grocery.randomsoftsolution.in";
axios.defaults.crossDomain = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

function GetCompanyData(limit, start) {
  const subhead = {
    getCompanydata: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getCompanydataBody: {
        limit: limit,
        start: start,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getCompanydata',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
          
         },
      }
    )
    .then(response => {
      return response;
    });
}

function GetmodeldataBycomanyname(limit, start, cName) {
  const subhead = {
    getModeldataByName: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getModeldataByNameBody: {
        limit: limit,
        start: start,
        cName: cName,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getModeldatabycName',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
          
        },
      }
    )
    .then(response => {
      return response;
    });
}

function Getcategory(limit, start) {
  const subhead = {
    getCotegory: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getCotegoryBody: {
        limit: limit,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getcategory',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
         
        },
      }
    )
    .then(response => {
      return response;
    });
}

function Subcategorydata(limit, start, catName) {
  const subhead = {
    getsubcategory: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getsubcategoryBody: {
        catName: catName,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getsubcategory',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
         
        },
      }
    )
    .then(response => {
      return response;
    });
}

function GetuniversalProduct(limit, start, productType, catId) {
  const subhead = {
    getUniversalProduct: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getUniversalProductBody: {
        limit: limit,
        start: start,
        product_type: productType,
        catId: catId,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getUniversalProduct',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
         
        },
      }
    )
    .then(response => {
      return response;
    });
}

function GetunicomboProduct(limit, start, productType) {
  const subhead = {
    getcomboProduct: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getcomboProductBody: {
        limit: limit,
        start: start,
        ptype: productType,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getcomboProduct',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
         
        },
      }
    )
    .then(response => {
      return response;
    });
}

function Getproductdata(
  limit,
  start,
  cName,
  mName,
  catName,
  subcatName,
  year,
  proName
) {
  const subhead = {
    getProduct: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getProductBody: {
        limit: 5,
        start: start,
        companyName: cName,
        modelName: mName,
        categoryName: catName,
        subcategoryName: subcatName,
        year: year,
        productName: proName,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getProduct',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

function GetSingleProduct(proID) {
  let subhead = {
    getSingleProduct: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getSingleProductBody: {
        proID: proID,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  return axios
    .post(
      '/decapi/getSingleProduct',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

function insertreviews(proID, name, email, msg, star) {
  let subhead = {
    insertProductReview: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      insertProductReviewBody: {
        proID: proID,
        name: name,
        email: email,
        review: msg,
        rating: star,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  return axios
    .post(
      '/decapi/insertProductReview',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

function GetAllProductreviews(proID, star) {
  let subhead = {
    getProductreview: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getProductreviewBody: {
        proID: proID,
        star: star,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getProductreview',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

function validemail(email) {
  if (email) {
    const subhead = {
      validemail: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        validemailBody: {
          email: email,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);

    return axios
      .post(
        '/decapi/validemail',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function userRegister(fname, lname, email, pass, mob) {
  if (fname && lname && email && pass && mob) {
    const subhead = {
      userRegister: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        userRegisterBody: {
          fName: fname,
          lName: lname,
          email: email,
          mobile: mob,
          password: pass,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);

    return axios
      .post(
        '/decapi/userRegister',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}

function userLogin(email, pass) {
  if (email && pass) {
    const subhead = {
      userLogin: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        userLoginBody: {
          email: email,
          password: pass,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);

    return axios
      .post(
        '/decapi/userLogin',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}

function GetsessionData() {
  if (auth.getSessionToken()) {
    let token = auth.getSessionToken();

    let subhead = {
      tokenDecode: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);

    return axios
      .post(
        '/decapi/tokenDecode',
        { RequestEncyptBody: request },
        {
          headers: {
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  } else {
    return false;
  }
}

function Getproductbyid(proID) {
  let subhead = {
    getSingleProduct: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getSingleProductBody: {
        proID: proID,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getSingleProduct',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function getCartAmount(array) {
  const subhead = {
    getCartAmount: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getCartAmountBody: {
        prodata: array,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getCartAmount',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function Getsavecartinlogin(array) {
  if (array) {
    if (auth.getSessionToken()) {
      const subhead = {
        getSaveCartinlogin: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },
          getSaveCartinloginBody: {
            prodata: array,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);

      const token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/getSaveCartinlogin',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          return response;
        });
    }
  }
}

function Getusercartdata() {
  if (auth.getSessionToken()) {
    const subhead = {
      getUserCartdata: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    let token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getUserCartdata',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function GetupdateCartquntity(pid, pqty, type) {
  if (pid && pqty && type) {
    if (auth.getSessionToken()) {
      let subhead = {
        getupdatequntity: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },

          getupdatequntityBody: {
            id: pid,
            qty: pqty,
            updateType: type,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      let token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/getupdateCartquntity',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          return response;
        });
    }
  }
}

function Deletecartitem(pid) {
  if (auth.getSessionToken()) {
    let subhead = {
      deletecartitem: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },

        deletecartitemBody: {
          id: pid,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    let token = auth.getSessionToken();

    return axios
      .post(
        '/decapi/deletecartitem',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function insertCart(array) {
  if (auth.getSessionToken()) {
    const subhead = {
      insertCart: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        insertCartBody: {
          prodata: array,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    let token = auth.getSessionToken();

    return axios
      .post(
        '/decapi/insertCart',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function getCupondata() {
  if (auth.getSessionToken()) {
    let subhead = {
      getCupondata: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    let token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getCupondata',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function getShipingamount(amount) {
  if (amount) {
    if (auth.getSessionToken()) {
      let subhead = {
        getShippingAmount: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },

          getShippingAmountBody: {
            amount: amount,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      let token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/getShippingAmount',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          return response;
        });
    }
  }
}

function getCheckCoupan(coupon) {
  if (auth.getSessionToken()) {
    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getCheckCoupan',
        {
          getCheckCoupan: {
            subHeader: {
              service_appType: APP_Type,
              service_keyType: APP_Key_Type,
            },
            getCheckCoupanBody: {
              coupon: coupon,
            },
          },
        },
        {
          headers: {
           
            'X-Api-Key': APP_Key_ID,
            'X-Api-Secret-Key': APP_secret_Key,
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function getState() {
  let subhead = {
    getState: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  return axios
    .post(
      '/decapi/getState',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function getCitydata(state) {
  let subhead = {
    getCity: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getCityBody: {
        stateName: state,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  return axios
    .post(
      '/decapi/getCity',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function getTimeSlot() {
  let subhead = {
    getTimeSlot: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);

  return axios
    .post(
      '/decapi/getTimeSlot',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function placeOrder(array) {
  if (auth.getSessionToken()) {
    let subhead = {
      placeOrder: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        placeOrderBody: {
          orderData: array,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);

    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/placeOrder',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        if (response.status === 200 && response.statusText === 'OK') {
          let dataApidatat = response.data.ResponseEncyptBody;
          let dataApi = authjt.Datadecode(dataApidatat);
          if (dataApi) {
            return dataApi;
          }
        }
      });
  }
}

function getAddressUser(array) {
  if (auth.getSessionToken()) {
    const encrypdataresponse = {
      getAddressUser: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };

    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getAddressUser',
        orderencrypt(encrypdataresponse),
        {
          headers: {
           
            'X-Api-Key': APP_Key_ID,
            'X-Api-Secret-Key': APP_secret_Key,
            Token: token,
          },
        }
      )
      .then(response => {
        if (response.status === 200 && response.statusText === 'OK') {
          const dataApi = response.data;
          return decryptdataresponse(dataApi);
        }
      });
  }
}

function saveWishliast(id, name) {
  if (id && name) {
    if (auth.getSessionToken()) {
      const subhead = {
        saveWishliast: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },
          saveWishliastBoby: {
            id: id,
            name: name,
            un: decrypt(id),
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      const token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/addwishlist',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          if (response.status === 200 && response.statusText === 'OK') {
            const dataApi = response.data.ResponseEncyptBody;
            if (dataApi) {
              return authjt.Datadecode(dataApi);
            }
          }
        });
    }
  }
}

function checkProductInWishlist(id) {
  if (id) {
    if (auth.getSessionToken()) {
      const subhead = {
        checkWishliast: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },
          checkWishliastBoby: {
            Pid: id,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      const token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/checkProductInWishlist',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          if (response.status === 200 && response.statusText === 'OK') {
            const dataApi = response.data.ResponseEncyptBody;
            if (dataApi) {
              return authjt.Datadecode(dataApi);
            }
          }
        });
    }
  }
}

function getWishlistdata(id) {
  if (auth.getSessionToken()) {
    const subhead = {
      getWishlistdata: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getWishlistdata',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  } else {
    return false;
  }
}

function ProductRemoveWishlist(id) {
  if (id) {
    if (auth.getSessionToken()) {
      const subhead = {
        removeWishliast: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },
          removeWishliastBoby: {
            Pid: id,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      const token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/ProductRemoveWishlist',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          if (response.status === 200 && response.statusText === 'OK') {
            const dataApi = response.data.ResponseEncyptBody;
            if (dataApi) {
              return authjt.Datadecode(dataApi);
            }
          }
        });
    }
  }
}

function getallorder() {
  if (auth.getSessionToken()) {
    const subhead = {
      getOrder: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getallorder',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  } else {
    return false;
  }
}

function getSingleorder(id) {
  if (auth.getSessionToken()) {
    const subhead = {
      getSingleorder: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        getSingleorderBoby: {
          id: id,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getSingleorder',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  } else {
    return false;
  }
}

function getcolors() {
  const subhead = {
    getcolors: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  const token = auth.getSessionToken();
  return axios
    .post(
      '/decapi/getcolors',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
          Token: token,
        },
      }
    )
    .then(response => {
      return response;
    });
}

function registerVehicle(company, model, year, color, vnumber) {
  if (company && model && year) {
    if (auth.getSessionToken()) {
      const subhead = {
        registerVehicle: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },
          registerVehicleBoby: {
            company: company,
            model: model,
            year: year,
            color: color,
            vnumber: vnumber,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      const token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/registerVehicle',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          return response;
        });
    } else {
      return false;
    }
  }
}

function getvahicledata() {
  if (auth.getSessionToken()) {
    const subhead = {
      getvahicledata: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getvahicledata',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function removeVehicle(id) {
  if (id) {
    if (auth.getSessionToken()) {
      const subhead = {
        removeVehicle: {
          subHeader: {
            service_appType: APP_Type,
            service_keyType: APP_Key_Type,
          },
          removeVehicleBoby: {
            id: id,
          },
        },
      };
      let request = authjt.Datagenrate(subhead);
      const token = auth.getSessionToken();
      return axios
        .post(
          '/decapi/removeVehicle',
          { RequestEncyptBody: request },
          {
            headers: {
             
              Hkey: headerkeys(),
              Token: token,
            },
          }
        )
        .then(response => {
          return response;
        });
    } else {
      return false;
    }
  }
}

function getproductforvehicle(id, limit, start, type) {
  if (auth.getSessionToken()) {
    const subhead = {
      getproductrelativevehicle: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        getproductrelativevehicleBoby: {
          id: id,
          limit: limit,
          start: start,
          type: type,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    const token = auth.getSessionToken();
    return axios
      .post(
        '/decapi/getproductrelativevehicle',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
            Token: token,
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function getblogall(limit, start) {
  const subhead = {
    getBlogall: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getBlogallBody: {
        limit: limit,
        start: start,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  return axios
    .post(
      '/decapi/getBlogall',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function getBlogdetail(id) {
  const subhead = {
    getBlogall: {
      subHeader: {
        service_appType: APP_Type,
        service_keyType: APP_Key_Type,
      },
      getBlogallBody: {
        id: id,
      },
    },
  };
  let request = authjt.Datagenrate(subhead);
  return axios
    .post(
      '/decapi/getBlogdetail',
      { RequestEncyptBody: request },
      {
        headers: {
         
          Hkey: headerkeys(),
        },
      }
    )
    .then(response => {
      return response;
    });
}

function addblogreviews(msg, email, name, id, website) {
  if (msg && email && name && id) {
    const subhead = {
      addblogreviews: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        addblogreviewsBody: {
          msg: msg,
          email: email,
          name: name,
          id: id,
          website: website,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    return axios
      .post(
        '/decapi/addblogreviews',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function getBlogreviews(id) {
  if (id) {
    const subhead = {
      getBlogreviews: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        getBlogreviewsBody: {
          id: id,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    return axios
      .post(
        '/decapi/getBlogreviews',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function googlelogin(fname, lname, email, googleId, imageUrl) {
  if ((fname, lname, email, googleId)) {
    const subhead = {
      googlelogin: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        googleloginBody: {
          fName: fname,
          lName: lname,
          email: email,
          googleId: googleId,
          image: imageUrl,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
return axios
      .post(
        '/decapi/googlelogin',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}

function userForgotpass(email) {
  if (email) {
    const subhead = {
      userForgotpass: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        userForgotpassBody: {
          email: email,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    return axios
      .post(
        '/decapi/userForgotpass',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

function checkforgot(ur) {
  if (ur) {
    const subhead = {
      checkforgot: {
        subHeader: {
          service_appType: APP_Type,
          service_keyType: APP_Key_Type,
        },
        checkforgotBody: {
          url: ur,
        },
      },
    };
    let request = authjt.Datagenrate(subhead);
    return axios
      .post(
        '/decapi/checkforgot',
        { RequestEncyptBody: request },
        {
          headers: {
           
            Hkey: headerkeys(),
          },
        }
      )
      .then(response => {
        return response;
      });
  }
}

export {
  GetuniversalProduct,
  GetCompanyData,
  GetmodeldataBycomanyname,
  Getcategory,
  Subcategorydata,
  GetunicomboProduct,
  Getproductdata,
  GetSingleProduct,
  insertreviews,
  GetAllProductreviews,
  validemail,
  userRegister,
  userLogin,
  GetsessionData,
  Getproductbyid,
  getCartAmount,
  Getsavecartinlogin,
  Getusercartdata,
  GetupdateCartquntity,
  Deletecartitem,
  insertCart,
  getCupondata,
  getShipingamount,
  getCheckCoupan,
  getState,
  getCitydata,
  getTimeSlot,
  placeOrder,
  getAddressUser,
  saveWishliast,
  checkProductInWishlist,
  getWishlistdata,
  ProductRemoveWishlist,
  getallorder,
  getSingleorder,
  getcolors,
  registerVehicle,
  getvahicledata,
  removeVehicle,
  getproductforvehicle,
  getblogall,
  getBlogdetail,
  addblogreviews,
  getBlogreviews,
  googlelogin,
  userForgotpass,
  checkforgot,
};
